// https://stackoverflow.com/questions/27440437/async-load-inside-replace-function
export function replaceAsync(str: string, re: RegExp, callback: (...args: any[]) => Promise<string>) {
    str = String(str);
    let parts = [], i = 0;
    if (Object.prototype.toString.call(re) === "[object RegExp]") {
        if (re.global)
            re.lastIndex = i;
        let m;
        while (m = re.exec(str)) {
            var args = m.concat([String(m.index), m.input]);
            parts.push(str.slice(i, m.index), callback.apply(null, args));
            i = re.lastIndex;
            if (!re.global)
                break; // for non-global regexes only take the first match
            if (m[0].length === 0)
                re.lastIndex++;
        }
    } else {
        i = str.indexOf(String(re));
        parts.push(str.slice(0, i), callback.apply(null, [re, i, str]));
        i += String(re).length;
    }
    parts.push(str.slice(i));
    return Promise.all(parts).then(function(strings) {
        return strings.join("");
    });
}