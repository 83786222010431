import {useEffect, useState} from "react"
import Markdown from "markdown-to-jsx"
import Code from "./Code"
import Image from "./Image"
import '../../styles/Post.css'
import convertFromObsidian from "../../util/MarkdownConverter";
import {getPostContentById, PostMetadata} from "../../util/BackendController";

interface PostInterface {
    post: PostMetadata
}

const Post = (props: PostInterface) => {
    const [content, setContent] = useState("")
    const [isDark, setIsDark] = useState(true)
    const cover = require('../../assets/img/cover.jpeg')

    useEffect(() => {
        const convertContent = async () => {
            let rawContent = await getPostContentById(props.post.id)
            let convertedContent = await convertFromObsidian(rawContent)
            setContent(convertedContent)
        }
        convertContent()
    }, [props.post])

    return (
        <div className="article-wrapper">
            <article>
                <header>
                    <div className="article__cover">
                        <img
                            src={cover}
                            alt="my-cover"
                        />
                    </div>
                </header>
                <main>
                    <Markdown
                        options={{
                            overrides: {
                                Code: {
                                    component: Code,
                                    props: {
                                        isDark,
                                        setIsDark
                                    }
                                },
                                img: {
                                    component: Image
                                }
                            }
                        }}
                    >
                        {content}
                    </Markdown>
                </main>
            </article>
        </div>
    )
}

export default Post