import './styles//App.css';
import {HashRouter as Router, Route, Routes} from "react-router-dom";
import Post from "./components/posts/Post.tsx";
import {useEffect, useState} from "react";
import Home from "./components/home/Home";
import HeaderIcon from "./components/HeaderIcon";
import {getPosts} from "./util/BackendController";

function App() {
    const [posts, setPosts] = useState([])

    useEffect(() => {
        async function fetchPosts() {
            setPosts(await getPosts())
        }
        fetchPosts()
    }, [])

    return (<Router>
        <header className='header'>
            <div className='header__content'>
              <HeaderIcon />
            </div>
        </header>
        <main className='main'>
            <div style={{ padding: 8 }}>
                <Routes>
                    <Route exact path={'/'} element={<Home posts={posts}/>} />
                    { posts.map((post, index) => {
                        return (
                            <Route key={index}
                                   exact path={`/posts/${post.path}`}
                                   element={<Post post={post}/>}
                            />
                        )
                    })}
                </Routes>
            </div>
        </main>
    </Router>
  );
}

export default App;
