import axios from "axios";
import config from "../config.json";

export interface PostMetadata {
    id: number,
    title: string,
    path: string,
    description: string,
    createdAt: string,
    authors: Array<{
        id: number,
        name: string
    }>
    tags: Array<{
        id: number,
        name: string
    }>
}

export async function getPosts() {
    return await axios.get(`${config.backendBaseUrl}/posts`).then(response => {
        return response.data.map((postData: any) => {
            return convertPostDataToPost(postData)
        })
    });
}

export async function getPostContentById(postId: number) {
    return await axios.get(`${config.backendBaseUrl}/postContent/${postId}`).then(response => {
        if (Object.keys(response.data).length === 0) {
            // Post does not exist
            return undefined
        }
        return response.data.markdownContent
    })
}

export async function getPostPathByName(postName: string) {
    return await axios.get(`${config.backendBaseUrl}/postPath/${postName}`).then(response => {
        if (Object.keys(response.data).length === 0) {
            // Post does not exist
            return undefined
        }
        return response.data.path
    })
}

function convertPostDataToPost(postData: any) {
    return {
        id: Number(postData.id),
        title: postData.title,
        path: postData.path,
        description: postData.description,
        createdAt: postData.createdAt,
        authors: postData.authors.map((author: any) => {
            return {
                id: Number(author.id),
                name: author.name
            }
        }),
        tags: postData.tags.map((tag: any) => {
            return {
                id: Number(tag.id),
                name: tag.name
            }
        })
    }
}