import {Link} from "react-router-dom";

export default function HeaderIcon() {
    const icon = require('../assets/img/beanIcon.png')

    return (
        <Link to={`/`} style={{ textDecoration: 'none' }}>
            <div className='logo'>
                <img
                    src={icon}
                    alt="icon"
                    style={{
                        maxHeight: "40px"
                    }}
                />
                <strong>Bean Blog</strong></div>
        </Link>
    )
}