import PostCard from "./PostCard";
import {PostMetadata} from "../../util/BackendController";

interface HomeInterface {
    posts: Array<PostMetadata>
}

export default function Home(props: HomeInterface) {
    return <div>
        { props.posts.map((post, index) => {
            return <PostCard post={post} />
        }) }
    </div>
}