import {replaceAsync} from "./StringOperations";
import {getPostPathByName} from "./BackendController";
import config from "../config.json";

export default async function convertFromObsidian(markdown: string): Promise<string> {
    const regexToReplace = [
        {
            // Code
            regex: /```(java|javascript|kotlin|jsx).*\r*\n((.|\r*\n)*?)```/ig,
            replacer: async (match: string, language: string, code: string) => {
                return `<Code language='${language}'>${code}</Code>`
            }
        },
        {
            // Notes
            regex: /```ad-(note|abstract|info|tip|success|question|warning|failure|danger|bug|example|quote).*\r*\ntitle:(.*)\r*\n((.|\r*\n)*?)```/ig,
            replacer: async (match: string, type: string, title: string, content: string) => {
                return `> **${title}**\n>\n> ${content.replace(/\n/g, "\n> ")}`
            }
        },
        {
            // Link to other post
            regex: /[^!]\[\[(.*?)]]/ig,
            replacer: async (match: string, name: string) => {
                return await getUrlToPostIfExists(name)
            }
        },
        {
            // Image
            regex: /!\[\[(.*?)]]/ig,
            replacer: async (match: string, name: string) => {
                const fileName = name.split("/")[name.split("/").length-1]
                return `![${name}](${config.backendBaseUrl}/images/${fileName})`
            }
        },
        {
            // remove front matter
            regex: /---(.|\r*\n)*?---/ig,
            replacer: async () => {
                return ""
            }
        }
    ]
    return await regexToReplace.reduce(async (acc, current ) => {
        return replaceAsync((await acc), current.regex, current.replacer)
    }, Promise.resolve(markdown))
}

async function getUrlToPostIfExists(postName: string): Promise<string> {

    let path = await getPostPathByName(postName)
    if (path !== undefined) {
        // Post exists
        const url = `${window.location.origin}/#/posts/${path}`
        return `[${postName}](${url})`
    } else {
        // Post does not exists
        return postName
    }
}