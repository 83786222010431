import {Link} from "react-router-dom";
import {PostMetadata} from "../../util/BackendController";

interface PostCardInterface {
    post: PostMetadata
}

export default function PostCard(props: PostCardInterface) {

    return <Link to={`/posts/${props.post.path}`} style={{ textDecoration: 'none' }}>
        <div style={{
            backgroundColor: "#292929",
            borderRadius: 6,
            margin: 16,
            padding: 16
        }}>
            { props.post.title }
        </div>
    </Link>
}